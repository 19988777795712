<template>
    <div class="orders-container">
        <nav-bar show-back="1">
            <div class="nav-title">Pesananku</div>
        </nav-bar>
        <ul class="orders">
            <li v-for="(data, index) in dataList" :key="index" @click="handleLiClick(data)">
                <div class="header">
                    <div class="orderId">{{data.orderId}}</div>
                    <div class="status">{{formatOrderStatus(data.status)}}</div>
                </div>
                <div class="content">
                    <div class="img-container">
                        <img :src="data.good_thumbnail" alt="">
                    </div>
                    <div class="title">{{data.good_title}}</div>
                    <div class="price">
                        <span>Rp</span>
                        <span>{{`${data.goodPrice}`}}</span>
                        <div class="amount">{{`x${data.orderAmount}`}}</div>
                    </div>
                </div>
                <div class="footer">
                    <span class="total">Total</span>
                    <span class="final">{{`Rp ${data.goodPrice * data.orderAmount}`}}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import NavBar from "../../../components/navBar.vue";
export default {
  components: { NavBar },
  data(){
      return {
          dataList: [
              {
                  id: 1,
                  orderId: '#0001',
                  status: 1,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 2,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 1,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 2,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 1,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 2,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 1,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 2,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 1,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 2,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
              {
                  id: 1,
                  orderId: '#0001',
                  status: 1,        // 0: 待发货，1: 待收货，2: 已收货
                  createTime: Date.now(),
                  goodPrice: 9900,
                  orderAmount: 1,
                  good_title: 'Pulsa GRATIS sebesar Rp5.000,-',
                  good_thumbnail: "https://wowssa.s3.ap-southeast-1.amazonaws.com/dev/NewUser_01.png",
                  payment_method: '️Cash on delivery'
              },
          ]
      }
  },
  methods: {
      formatOrderStatus(status){
          let res;
          if(status === 1){
              res = 'Tunggu pengiriman';        // 等待发货
          } else if(status === 2){
              res = '️Menunggu resi';            // 等待收获
          } else if(status === 3){
              res = '️Diterima';                 // 已接受
          } else if(status === 4){
              res = '️Dikembalikan';             // 已退货
          }
          return res;
      },
      handleLiClick(data){
          this.$router.push({
              name: 'groups/mine/orderDetail',
              query: {
                  orderId: data.orderId
              },
          })
      }
  },
}
</script>

<style lang="scss" scoped>
.orders-container{
    display: block;
    width: 100%;
    min-height: 100%;
    background-color: #f0f5fa;
}
.orders{
    padding: 12px;
    box-sizing: border-box;
    li{
        padding: 15px;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 15px;
        .header{
            display: flex;
            justify-content: space-between;
        }
        .content{
            margin-top: 10px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            .img-container{
                border-radius: 10px;
                width: 100px;
                height: 100px;
                img{
                    max-width: 100%;
                }
            }
        }
        .title{
            margin-left: 10px;
        }
        .price{
            margin-left: 10px;
        }
        .amount{
            text-align: right;
            color: #bbb;
        }
        .footer{
            width: 100%;
            border-top: 1px solid #eee;
            // border-bottom: 1px solid #eee;
            height: 35px;
            line-height: 35px;
            text-align: right;
            span.total{
                color: #bbb;
            }
            span.final{
                font-size: 16px;
                margin-left: 10px;
                font-weight: bolder;
                // color: red;
            }
        }
    }
}
</style>